import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core';
import { ContactSupportOutlined } from '@material-ui/icons';
import Paper from "@material-ui/core/Paper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
      width: 250,
      color: 'blue',
      //backgroundColor: '#141D24', 
      bgcolor: '#4f5a6b',
    },
  },
};

const useStyles = makeStyles({
    paper: {
        border: "5px solid black"
      },
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#4f5a6b",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "& .MuiOutlinedInput-input": {
        color: "white"
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "white",
      },
      "& .MuiInputLabel-outlined": {
        color: "#4f5a6b"
      },
      "&:hover .MuiInputLabel-outlined": {
        color: "white",
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "white"
      },
      "& .MuiSelect-icon": {
        color: "#4f5a6b"
      },
      "& .MuiAutocomplete-paper": {
          color: 'white',
          backgroundColor: 'black',
      },
      "& .MuiAutocomplete-groupUl": {
        color: 'white',
        backgroundColor: 'black',
      },
    },
    button1: {
        color: '#141D24',
        backgroundColor: "white",
        '&:hover': {
          backgroundColor: '#4f5a6b',
          color: '#141D24',
        },
       '&:disabled': {
          backgroundColor: '#1b2730',
          color: '#141D24',
       }, 
    },
  });


function Download (link) {
    window.open(link, "_self")
}

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog() {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      title: '',
      link: '',
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    link: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      title: dialogValue.title,
      link: parseInt(dialogValue.link, 10),
    });

    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        
        value={value}
        onOpen={console.log('shit')}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                title: newValue,
                link: '',
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              title: newValue.inputValue,
              link: '',
            });
          } else {
            setValue(newValue);
            if (downloadable.includes(newValue)){
                Download(newValue.link);

            };
          };
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Request "${params.inputValue}"`,
            });
          } 
          return filtered;
        }}
        
        options={downloadable}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        
        autoComplete
        autoHighlight
        selectOnFocus
        clearOnBlur
        clearOnEscape
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        sx={{ minWidth: 200, maxWidth: 750, padding: 0, backgroundColor: '#141D24' }}
        freeSolo
        renderInput={(params) => <TextField {...params} label="Search 'n Download" />}
        
        className={classes.root}
      />

    <p></p>

      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={{backgroundColor: '#4f5a6b', color: 'white'}}>Missing something?</DialogTitle>
          <DialogContent sx={{backgroundColor: '#4f5a6b', color: 'white'}}>
            <DialogContentText sx={{backgroundColor: '#4f5a6b', color: 'white'}}>
              Sometimes, only one app is missing, and the whole website seems empty. You shouldn't feel like that ⁠— tell me what's missing and we'll fix it.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              value={dialogValue.title}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  title: event.target.value,
                })
              }
              label="name"
              type="text"
              variant="standard"
              sx={{ minWidth: 350, color: 'white' }}
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.year}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  year: event.target.value,
                })
              }
              label="link"
              type="href"
              variant="standard"
              sx={{ minWidth: 350, color: 'white'}}
            />
          </DialogContent>
          <DialogActions sx={{backgroundColor: '#4f5a6b', color: 'white'}}>
            <Button sx={{color: 'white', backgroundColor: '#141D24'}} onClick={handleClose}>Cancel</Button>
            <Button sx={{color: 'white', backgroundColor: '#141D24'}} type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

const downloadable = [
      {
          title: '.NET 5, 4.8 Runtime',
          link: 'https://ninite.com/.net4.8-.net5-.netx5/ninite.exe',
      },
      {
          title: 'Acronis Drive Monitor',
          link: 'https://file.avlinks.net/api/public/dl/sFPo19w6',
      },
      {
          title: 'Acronis PE',
          link: 'https://file.avlinks.net/api/public/dl/rya0sLmq',
      },
      {
          title: 'Adobe Acrobat Reader DC',
          link: 'https://file.avlinks.net/api/public/dl/Elr6QCja',
      },
      {
          title: 'Adobe Creative Cloud',
          link: 'https://creativecloud.adobe.com/en/apps/download/creative-cloud'
      },
      {
          title: 'Advanced IP Scanner',
          link: 'https://file.avlinks.net/api/public/dl/uw-YwdNk',
      },
      {
          title: 'AdwCleaner',
          link: 'https://downloads.malwarebytes.com/file/adwcleaner',
      },
      {
          title: 'Angry IP Scanner',
          link: 'https://file.avlinks.net/api/public/dl/tDnpje3m',
      },
      {
          title: 'Autoruns - SysInternals',
          link: 'https://download.sysinternals.com/files/Autoruns.zip',
      },
      {
          title: 'AVG Removal Tool',
          link: 'http://files-download.avg.com/util/tools/AVG_Remover.exe',
      },
      {
          title: 'Belarc Advisor',
          link: 'https://file.avlinks.net/api/public/dl/RvnoUZOz',
      },
      {
          title: 'Caffeine',
          link: 'https://www.zhornsoftware.co.uk/caffeine/caffeine.zip',
      },
      {
          title: 'CCleaner - Installer (latest)',
          link: 'https://bits.avcdn.net/productfamily_CCLEANER/insttype_FREE/platform_WIN_PIR/installertype_ONLINE/build_RELEASE',
      },
      {
          title: 'CCleaner - Portable (v5.86)',
          link: 'https://download.ccleaner.com/portable/ccsetup586.zip',
      },
      {
          title: 'Citrix Receiver',
          link: 'https://www.citrix.com/downloads/citrix-receiver/windows/receiver-for-windows-latest.html',
      },
      {
          title: 'Citrix Workspace',
          link: 'https://www.citrix.com/downloads/workspace-app/windows/workspace-app-for-windows-latest.html#ctx-dl-eula',
      },
      {
          title: 'CrystalDiskMark',
          link: 'https://file.avlinks.net/api/public/dl/QW5ohDPk',
      },
      {
          title: 'Datto Cloud Continuity',
          link: 'https://cf-dl.datto.com/cloudcontinuity/DattoCloudContinuityInstaller.exe',
      },
      {
          title: 'Display Driver Uninstaller',
          link: 'https://file.avlinks.net/api/public/dl/_e4r9dMo',
      },
      {
          title: 'ESET Online Scanner',
          link: 'https://download.eset.com/com/eset/tools/online_scanner/latest/esetonlinescanner.exe',
      },
      {
          title: 'Everything - Fast Windows Search',
          link: 'https://file.avlinks.net/api/public/dl/siQO3yQW',
      },
      {
          title: 'FurMark',
          link: 'https://file.avlinks.net/api/public/dl/ADtNKnYa',
      },
      {
          title: 'GSmartControl',
          link: 'https://file.avlinks.net/api/public/dl/_17OsFDS',
      },
      {
          title: 'HashTab',
          link: 'http://implbits.com/products/hashtab/HashTab_v6.0.0.34_Setup.exe',
      },
      {
          title: 'Hitman Pro x64',
          link: 'https://files.surfright.nl/HitmanPro_x64.exe',
      },
      {
          title: 'HWiNFO',
          link: 'https://file.avlinks.net/api/public/dl/vK2eE1Nw',
      },
      {
          title: 'Intel Burn Test',
          link: 'https://file.avlinks.net/api/public/dl/gcGcUbYM',
      },
      {
          title: 'Java Runtime Environment (AdoptOpenJDK)',
          link: 'https://ninite.com/adoptjava8-adoptjavax11-adoptjavax8/ninite.exe',
      },
      {
          title: 'Malwarebytes - Offline Installer (v4.4.9)',
          link: 'https://data-cdn.mbamupdates.com/web/mb4-setup-consumer/offline/mb4-setup-consumer-4.4.9.142-1.0.1486-1.0.46222.exe',
      },
      {
          title: 'Malwarebytes - Online Installer (latest)',
          link: 'https://data-cdn.mbamupdates.com/web/mb4-setup-consumer/MBSetup.exe',
      },
      {
          title: 'McAfee Consumer Products Removal Tool',
          link: 'http://download.mcafee.com/products/licensed/cust_support_patches/MCPR.exe',
      },
      {
          title: 'Microsoft DaRT 10 x64',
          link: 'https://file.avlinks.net/api/public/dl/mfw0Egjp',
      },
      {
          title: 'Microsoft Office 365 (O365) - Online Installer (latest)',
          link: 'https://file.avlinks.net/api/public/dl/McR0zMU5',
      },
      {
          title: 'Microsoft Support and Recovery Assistant (SARA)',
          link: 'https://www.microsoft.com/en-us/download/confirmation.aspx?id=100607',
      },
      {
          title: 'NetSpot',
          link: 'https://file.avlinks.net/api/public/dl/AOiS6jxo',
      },
      {
          title: 'Norton Removal Tool (Can cause problems with ACT! databases)',
          link: 'http://liveupdate.symantecliveupdate.com/upgrade/RnR/NRnR.exe',
      },
      {
          title: 'Port Query UI',
          link: 'https://file.avlinks.net/api/public/dl/u2hN6fiE',
      },
      {
          title: 'Prime95',
          link: 'https://file.avlinks.net/api/public/dl/V0aWZvL-',
      },
      {
          title: 'Revo Uninstaller',
          link: 'https://file.avlinks.net/api/public/dl/_Sn0HJ0F',
      },
      {
          title: 'RogueKiller x64',
          link: 'https://download.adlice.com/api?action=download&app=roguekiller&type=x64',
      },
      {
          title: 'RKill',
          link: 'https://download.bleepingcomputer.com/grinler/rkill.exe',
      },
      {
          title: 'Rufus',
          link: 'https://github.com/pbatard/rufus/releases/download/v3.17/rufus-3.17p.exe'
      },
      {
          title: 'Seagate SeaTools',
          link: 'https://file.avlinks.net/api/public/dl/ZSZbzyyA',
      },
      {
          title: 'SonicWall NetExtender',
          link: 'https://software.sonicwall.com/NetExtender/NetExtender-10.2.315.MSI',
      },
      {
          title: 'SonicWall Global VPN Client',
          link: 'https://software.sonicwall.com/GlobalVPNClient/184-010739-00_REV_A_GVCSetup64.exe'
      },
      {
          title: 'Spotify',
          link: 'https://download.scdn.co/SpotifySetup.exe',
      },
      {
          title: 'StreetSmart Edge',
          link: 'http://activetrader.schwab.com/SchwabServices/download.aspx?d=ssedge',
      },
      {
          title: 'SUPERAntiSpyware',
          link: 'https://securedownloads.superantispyware.com/SUPERAntiSpyware.exe',
      },
      {
          title: 'TCPView',
          link: 'http://download.sysinternals.com/files/TCPView.zip',
      },
      {
          title: 'TDSS Killer',
          link: 'http://media.kaspersky.com/utilities/VirusUtilities/EN/tdsskiller.exe',
      },
      {  
          title: 'TreeSize Portable',
          link: 'https://file.avlinks.net/api/public/dl/HMjhxWJv',
      },
      {
          title: 'Tweaking Repair',
          link: 'https://www.tweaking.com/files/setups/tweaking.com_windows_repair_aio_setup.exe',
      },
      {
          title: 'Unigine Superposition Benchmark',
          link: 'https://assets.unigine.com/d/Unigine_Superposition-1.1.exe',
      },
      {
          title: 'Unigine Valley Benchmark',
          link: 'https://assets.unigine.com/d/Unigine_Valley-1.0.exe',
      },
      {
          title: 'UserBenchmark',
          link: 'https://www.userbenchmark.com/resources/download/UserBenchMark.exe'
      },
      {
          title: 'WebEx Meetings',
          link: 'https://akamaicdn.webex.com/client/webexapp.msi',
      },
      {
          title: 'WebEx Teams',
          link: 'https://binaries.webex.com/WebexTeamsDesktop-Windows-Gold/Webex.exe',
      },
      {
          title: 'Western Digital Dashboard',
          link: 'https://file.avlinks.net/api/public/dl/bEMPixJd',
      },
      {
          title: 'WinDirStat',
          link: 'https://windirstat.mirror.wearetriple.com//wds_current_setup.exe',
      },
      {
          title: 'WireShark',
          link: 'https://file.avlinks.net/api/public/dl/F_S1xAF7',
      },
      {
          title: 'Windows 11 Installation Assistant',
          links: 'https://file.avlinks.net/api/public/dl/tvyauE71',
      },
      {
          title: 'Windows 11 x64 ISO',
          link: 'https://file.avlinks.net/api/public/dl/hjVSpGVM',
      },
      {
          title: 'Windows Update Assistant',
          link: 'https://go.microsoft.com/fwlink/?LinkID=799445',
      },
      {
          title: 'WhoCrashed',
          link: 'https://file.avlinks.net/api/public/dl/K7uWNAce',
      },
      {
          title: 'Zoom Client for Meetings',
          link: 'https://zoom.us/client/latest/ZoomInstaller.exe',

      },
]    
